<template>
	<v-row>
		<template v-if="permissaoDashboard">
			<DialogDashboards :dialog="dialog" />
			<v-col cols="12" md="12">
				<v-card>
					<v-card-text>
						<v-form>
							<v-row
								v-if="filtroCompleto.indexOf(usuario.idgrupo) != -1"
								:class="dashboard.dtultimaatualizacao ? 'mb-n13' : 'mb-n10'"
							>
								<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
									<InputDatePicker
										:disabled="carregando"
										v-model="busca.dtinicio"
										label="Data inicial"
										:outlined="true"
										:dense="true"
									/>
								</v-col>
								<v-col class="d-none d-md-block px-1">
									<InputDatePicker
										:disabled="carregando"
										v-model="busca.dtfim"
										label="Data final"
										:outlined="true"
										:dense="true"
									/>
								</v-col>
								<v-col class="d-none d-md-block px-1">
									<v-autocomplete
										:items="regionais"
										clearable
										item-text="regional"
										item-value="idregional"
										label="Regional"
										outlined
										dense
										:disabled="carregando"
										v-model="busca.idregional"
										@change="listarEmpresas()"
									></v-autocomplete>
								</v-col>
								<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
									<v-sheet class="text-center" height="100%">
										<v-row class="pa-3">
											<v-col cols="12" class="px-2 mb-n7">
												<v-autocomplete
													:items="regionais"
													clearable
													item-text="regional"
													item-value="idregional"
													label="Regional"
													outlined
													dense
													:disabled="carregando"
													v-model="busca.idregional"
													@change="listarEmpresas()"
												></v-autocomplete>
											</v-col>
											<v-col cols="6" class="px-2">
												<InputDatePicker
													:disabled="carregando"
													v-model="busca.dtinicio"
													label="Data inicial"
													:outlined="true"
													:dense="true"
												/>
											</v-col>
											<v-col cols="6" class="px-2">
												<InputDatePicker
													:disabled="carregando"
													v-model="busca.dtfim"
													label="Data final"
													:outlined="true"
													:dense="true"
												/>
											</v-col>
											<v-col cols="6">
												<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
											</v-col>
											<v-col cols="6">
												<v-btn text class="mt-n3" color="primary" @click="get(), sheet = !sheet">Filtrar</v-btn>
											</v-col>
										</v-row>
									</v-sheet>
								</v-bottom-sheet>
								<v-col cols="12" md="4" class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">
									<v-autocomplete
										:items="filiais.lista"
										clearable
										item-text="filial"
										item-value="idfilial"
										label="Filial"
										outlined
										dense
										:disabled="carregando"
										v-model="busca.idfiliais"
										multiple
										class="d-inline-flex d-md-none"
										append-outer-icon="mdi-menu"
										@click:append-outer="sheet = !sheet"
									>
										<template v-slot:selection="{ item, index }">
											<template v-if="index === 0">{{ item.filial }}</template>
											<span
												v-if="index === 1"
												class="grey--text text-caption ml-1"
											>(+{{ busca.idfiliais.length - 1 }})</span>
										</template>
									</v-autocomplete>
									<v-autocomplete
										:items="filiais.lista"
										clearable
										item-text="filial"
										item-value="idfilial"
										label="Filial"
										outlined
										dense
										:disabled="carregando"
										v-model="busca.idfiliais"
										multiple
										class="d-none d-md-block"
									>
										<template v-slot:selection="{ item, index }">
											<template v-if="index === 0">{{ item.filial }}</template>
											<span
												v-if="index === 1"
												class="grey--text text-caption ml-1"
											>(+{{ busca.idfiliais.length - 1 }})</span>
										</template>
									</v-autocomplete>
									<v-btn
										:disabled="carregando"
										class="ml-2 mt-1"
										color="primary"
										elevation="0"
										fab
										x-small
										@click="get()"
									>
										<v-icon>mdi-magnify</v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-row
								v-else-if="filtroComFilial.indexOf(usuario.idgrupo) != -1"
								:class="dashboard.dtultimaatualizacao ? 'mb-n13' : 'mb-n10'"
							>
								<v-col class="d-none d-md-block pl-2 pr-1 pl-lg-3">
									<InputDatePicker
										:disabled="carregando"
										v-model="busca.dtinicio"
										label="Data inicial"
										:outlined="true"
										:dense="true"
									/>
								</v-col>
								<v-col class="d-none d-md-block px-1">
									<InputDatePicker
										:disabled="carregando"
										v-model="busca.dtfim"
										label="Data final"
										:outlined="true"
										:dense="true"
									/>
								</v-col>
								<v-bottom-sheet class="d-inline-flex d-md-none" v-model="sheet">
									<v-sheet class="text-center" height="100%">
										<v-row class="pa-3">
											<v-col cols="6" class="px-2">
												<InputDatePicker
													:disabled="carregando"
													v-model="busca.dtinicio"
													label="Data inicial"
													:outlined="true"
													:dense="true"
												/>
											</v-col>
											<v-col cols="6" class="px-2">
												<InputDatePicker
													:disabled="carregando"
													v-model="busca.dtfim"
													label="Data final"
													:outlined="true"
													:dense="true"
												/>
											</v-col>
											<v-col cols="6">
												<v-btn text class="mt-n3" color="primary" @click="sheet = !sheet">Cancelar</v-btn>
											</v-col>
											<v-col cols="6">
												<v-btn text class="mt-n3" color="primary" @click="get(), sheet = !sheet">Filtrar</v-btn>
											</v-col>
										</v-row>
									</v-sheet>
								</v-bottom-sheet>
								<v-col class="pr-1 pl-3 pl-md-1 pr-lg-3 d-flex">
									<v-autocomplete
										:items="filiais.lista"
										clearable
										item-text="filial"
										item-value="idfilial"
										label="Filial"
										outlined
										dense
										:disabled="carregando"
										v-model="busca.idfiliais"
										multiple
										class="d-inline-flex d-md-none"
										append-outer-icon="mdi-menu"
										@click:append-outer="sheet = !sheet"
									>
										<template v-slot:selection="{ item, index }">
											<template v-if="index === 0">{{ item.filial }}</template>
											<span
												v-if="index === 1"
												class="grey--text text-caption ml-1"
											>(+{{ busca.idfiliais.length - 1 }})</span>
										</template>
									</v-autocomplete>
									<v-autocomplete
										:items="filiais.lista"
										clearable
										item-text="filial"
										item-value="idfilial"
										label="Filial"
										outlined
										dense
										:disabled="carregando"
										v-model="busca.idfiliais"
										multiple
										class="d-none d-md-block"
									>
										<template v-slot:selection="{ item, index }">
											<template v-if="index === 0">{{ item.filial }}</template>
											<span
												v-if="index === 1"
												class="grey--text text-caption ml-1"
											>(+{{ busca.idfiliais.length - 1 }})</span>
										</template>
									</v-autocomplete>
									<v-btn
										:disabled="carregando"
										class="ml-2 mt-1"
										color="primary"
										elevation="0"
										fab
										x-small
										@click="get()"
									>
										<v-icon>mdi-magnify</v-icon>
									</v-btn>
								</v-col>
							</v-row>
							<v-row v-else :class="dashboard.dtultimaatualizacao ? 'mb-n7' : 'mb-n4'">
								<v-spacer />
								<v-col cols="6" class="pl-2 pr-1">
									<InputDatePicker
										:disabled="carregando"
										v-model="busca.dtinicio"
										label="Data inicial"
										:outlined="true"
										:dense="true"
									/>
								</v-col>
								<v-spacer />
								<v-col cols="6" md="6" class="px-1 d-flex">
									<InputDatePicker
										:disabled="carregando"
										v-model="busca.dtfim"
										label="Data final"
										:outlined="true"
										:dense="true"
									/>
									<v-btn
										:disabled="carregando"
										class="ml-2 mt-1"
										color="primary"
										elevation="0"
										fab
										x-small
										@click="get()"
									>
										<v-icon>mdi-magnify</v-icon>
									</v-btn>
								</v-col>
								<v-spacer />
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions class="px-4 my-0 py-0">
						<small
							v-if="autoRefresh && !carregando"
							class="text--secondary text-caption"
						>Próxima atualização em: {{tempoRefresh}} segundos</small>
						<v-spacer />
						<span class="d-flex mb-n3">
							<v-switch
								:loading="carregando"
								v-model="autoRefresh"
								label="Atualização automática"
								color="primary"
								class="my-0 py-0"
							></v-switch>
						</span>
					</v-card-actions>
					<span
						v-if="dashboard.dtultimaatualizacao"
						class="text-caption text--disabled pl-3"
					>* Atualizado em: {{ dashboard.dtultimaatualizacao }}</span>
				</v-card>
			</v-col>
			<v-col cols="12" md="12">
				<v-row class="pa-2">
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" :title="`Venda do dia ${dia}`">
							<template v-if="dashboard.vendadia">{{ dashboard.vendadia | formataDinheiro }}</template>
							<template v-else>{{ 0 | formataDinheiro }}</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" :title="`Meta do dia ${dia}`">
							<template v-if="dashboard.metadia">{{ dashboard.metadia | formataDinheiro }}</template>
							<template v-else>Indefinida</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1" v-if="usuario.idgrupo != 7">
						<CardDashboard :loading="carregando" :title="`Margem do dia ${dia}`">
							<template v-if="dashboard.margemvendadia">{{ dashboard.margemvendadia.toFixed(2) }} %</template>
							<template v-else>0.00 %</template>
							<MenuInfo texto="Margem de venda com base no apoio a decisão" />
						</CardDashboard>
					</v-col>
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" title="Venda acumulada">
							<template v-if="dashboard.vendabruta">{{ dashboard.vendabruta | formataDinheiro }}</template>
							<template v-else>{{ 0 | formataDinheiro }}</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" title="Meta acumulada">
							<template v-if="dashboard.metavendas">{{ dashboard.metavendas | formataDinheiro }}</template>
							<template v-else>Indefinida</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1" v-if="usuario.idgrupo != 7">
						<CardDashboard :loading="carregando" title="Margem acumulada">
							<template v-if="dashboard.margemvendas">{{ dashboard.margemvendas.toFixed(2) }} %</template>
							<template v-else>0.00 %</template>
							<MenuInfo texto="Margem de venda com base no apoio a decisão" />
						</CardDashboard>
					</v-col>
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" title="Meta atingida (%)">
							<template
								v-if="dashboard.vendabruta && dashboard.metavendas"
							>{{ (dashboard.vendabruta / dashboard.metavendas * 100).toFixed(2) }} %</template>
							<template v-else>0.00.00 %</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1">
						<CardDashboard :loading="carregando" title="Meta do mês">
							<template v-if="dashboard.metames">{{ dashboard.metames | formataDinheiro }}</template>
							<template v-else>Indefinida</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1" v-if="usuario.idgrupo != 7">
						<CardDashboard :loading="carregando" title="Ticket médio pedidos / Qtd tickets">
							<template
								v-if="dashboard.ticketmedio"
							>{{ dashboard.ticketmedio | formataDinheiro }} / {{ (dashboard.vendabruta / dashboard.ticketmedio).toFixed(0) }}</template>
							<template v-else>{{ 0 | formataDinheiro }} / 0</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1" v-if="usuario.idgrupo != 7">
						<CardDashboard :loading="carregando" title="Ticket médio / clientes atendidos">
							<template
								v-if="dashboard.vendabruta && dashboard.clientesatendidos"
							>{{ (dashboard.vendabruta/dashboard.clientesatendidos) | formataDinheiro }} / {{ dashboard.clientesatendidos }}</template>
							<template v-else>{{ 0 | formataDinheiro }} / 0</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1" v-if="usuario.idgrupo != 7">
						<CardDashboard :loading="carregando" title="Valor vendido à vista">
							<template v-if="dashboard.valvendavista">
								{{ dashboard.valvendavista | formataDinheiro }}
								({{ dashboard.pervendavista }} %)
							</template>
							<template v-else>{{ 0 | formataDinheiro }}</template>
						</CardDashboard>
					</v-col>
					<v-col class="pa-1" v-if="usuario.idgrupo != 7">
						<CardDashboard :loading="carregando" title="Valor vendido à prazo">
							<template v-if="dashboard.valvendaprazo">
								{{ dashboard.valvendaprazo | formataDinheiro }}
								({{ dashboard.pervendaprazo }} %)
							</template>
							<template v-else>{{ 0 | formataDinheiro }}</template>
						</CardDashboard>
					</v-col>
				</v-row>
			</v-col>
			<template v-if="dashboard.vendafilial">
				<v-col
					cols="12"
					v-if="filtroComFilial.indexOf(usuario.idgrupo) != -1 && dashboard.vendafilial.length > 1"
					class="d-none d-md-block"
				>
					<v-card>
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-title>
							<span class="text-truncate">Meta atingida por filial</span>
							<v-spacer />
							<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
							<v-spacer />
							<v-btn
								:disabled="carregando"
								class="px-2 mb-1 ml-auto mt-2 mr-2"
								color="primary"
								elevation="0"
								small
								@click="abrirDialog(
                                    dashboard.vendafilial, 
									{
										descrfilial: {nome: 'Filial', tipo: 'texto'},
										valvenda: {nome: 'Venda realizada', tipo: 'dinheiro'},
										valmeta: {nome: 'Meta', tipo: 'dinheiro'},
										peratingimento: {nome: 'Atingido', tipo: 'porcentagem'},
										margem: {nome: 'Margem', tipo: 'porcentagem'},
									},
                                    'Venda realizada x Meta por filial'
                                )"
							>
								<v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
							</v-btn>
						</v-card-title>
						<v-card-text v-if="carregando">
							<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
						</v-card-text>
						<v-card-text v-else>
							<GraficoBarDashboard
								v-if="dashboard.vendafilial"
								:dataSeries="[
									{
										name: 'Atingido (%)',
										data: dashboard.vendafilial.map((v) => {return v.peratingimento.toFixed(2)})
									},                            
								]"
								:categories="dashboard.vendafilial.map((v) => {return v.descrfilial.replaceAll('(Loja 28)', '').replaceAll('Loja', '').replaceAll('( 31)', '')})"
								:horizontal="false"
								:money="false"
								:porcentagem="true"
								height="300"
								:distributed="true"
								:dataLabels="{ position : 'bottom', orientation : 'vertical', offsetY: 20 }"
								:color="corMod1(dashboard.vendafilial, 'peratingimento')"
							/>
							<span v-else>Nenhum registro encontrado</span>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col
					cols="12"
					v-if="filtroComFilial.indexOf(usuario.idgrupo) != -1 && dashboard.vendafilial.length > 1"
					class="d-block d-md-none"
				>
					<v-card>
						<v-progress-linear indeterminate absolute :active="carregando" />
						<v-card-title>
							<span class="text-truncate">Meta atingida por filial</span>
							<v-spacer />
							<v-btn
								:disabled="carregando"
								class="px-2 mb-1 ml-auto mt-2 mr-2"
								color="primary"
								elevation="0"
								small
								@click="abrirDialog(
                                    dashboard.vendafilial, 
									{
										descrfilial: {nome: 'Filial', tipo: 'texto'},
										valvenda: {nome: 'Venda realizada', tipo: 'dinheiro'},
										valmeta: {nome: 'Meta', tipo: 'dinheiro'},
										peratingimento: {nome: 'Atingido', tipo: 'porcentagem'},
										margem: {nome: 'Margem', tipo: 'porcentagem'},
									},
                                    'Venda realizada x Meta por filial'
                                )"
							>
								<v-icon size="18">mdi-magnify-plus-outline</v-icon>
							</v-btn>
							<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
						</v-card-title>
						<v-card-text v-if="carregando">
							<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
						</v-card-text>
						<v-card-text v-else>
							<template v-if="dashboard.vendafilial">
								<v-simple-table dense>
									<tbody>
										<tr v-for="(u,i) in dashboard.vendafilial" :key="i">
											<td style="height: 22px" width="30%" class="text-truncate text-caption">{{u.descrfilial}}</td>
											<td style="height: 22px">
												<v-progress-linear
													rounded
													:value="u.peratingimento"
													:color="shadeColor('#002D56', 100 - u.peratingimento / 3)"
													class="white--text"
													height="17"
												>
													<template v-slot:default="{ value }">
														<span class="text-caption">{{ value.toFixed(2) }}%</span>
													</template>
												</v-progress-linear>
											</td>
										</tr>
									</tbody>
								</v-simple-table>
							</template>
							<span v-else>Nenhum registro encontrado</span>
						</v-card-text>
					</v-card>
				</v-col>
			</template>
			<v-col cols="12" :md="usuario.idgrupo != 7 ? '12' : '3'">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title v-if="usuario.idgrupo != 7" class="col-12">
						<span class="text-truncate">Meta x Venda realizada por vendedor</span>
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
						<v-spacer />
						<v-btn
							:disabled="carregando"
							class="px-2 mb-1 ml-auto mt-2 mr-2"
							color="primary"
							elevation="0"
							small
							@click="abrirDialog(
                                dashboard.vendametavendedor, 
								dashboard.headervendedor,
                                'Meta x Venda realizada por vendedor'
                            )"
						>
							<v-icon size="18">mdi-magnify-plus-outline</v-icon>
							<span class="d-none d-md-block">Ver mais</span>
						</v-btn>
					</v-card-title>
					<v-card-title v-else class="col-12">Meta x Venda realizada</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<GraficoLineColumnDashboard
							v-if="dashboard.vendametavendedor"
							:dataSeries="[
								{
									name: 'Meta', 
									type: 'column', 
									data: dashboard.vendametavendedor.filter(v => {return v.vendedor != 'VENDEDOR'}).filter(v => {return v.vendedor != 'Caixa Rapido'}).filter(v => {return v.venda > 0}).slice(0,10).map((v) => {return v.meta.toFixed(2)})
								},
								{
									name: 'Realizado', 
									type: 'column', 
									data: dashboard.vendametavendedor.filter(v => {return v.vendedor != 'VENDEDOR'}).filter(v => {return v.vendedor != 'Caixa Rapido'}).filter(v => {return v.venda > 0}).slice(0,10).map((v) => {return v.venda.toFixed(2)})
								},
								{
									name: 'Atingido', 
									type: 'line', 
									data: dashboard.vendametavendedor.filter(v => {return v.vendedor != 'VENDEDOR'}).filter(v => {return v.vendedor != 'Caixa Rapido'}).filter(v => {return v.venda > 0}).slice(0,10).map((v) => {return v.atingido.toFixed(2)})
								},
							]"
							:labels="dashboard.vendametavendedor.filter(v => {return v.vendedor != 'VENDEDOR'}).filter(v => {return v.vendedor != 'Caixa Rapido'}).filter(v => {return v.venda > 0}).slice(0,10).map((v) => {return v.vendedor.split(' ')[0]})"
							:titlesY="[
								{ show: false, min: 0, max: maiorValor()}, 
								{ show: false, min: 0, max: maiorValor()}, 
								{ show: false, min: 0, max: maiorValor() / 1000}, 
							]"
							:tooltipsY="[
								{ enabled: true, formatter: function (val, data) { return parseFloat(val).toLocaleString('pt-br', {style: 'currency', currency:'BRL', }) } },
								{ enabled: true, formatter: function (val, data) { return parseFloat(val).toLocaleString('pt-br', {style: 'currency', currency:'BRL', }) } },
								{ enabled: true, formatter: function (val, data) { return `${val} %`} },
							]"
							height="300"
							:porcentagem="false"
							:money="false"
							:stroke="{curve: 'smooth', width: [0, 0, 6]}"
						/>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" class="d-none d-md-block" :md="usuario.idgrupo != 7 ? '6' : '9'">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title class="col-12">
						<span class="text-truncate">Venda por categoria (%)</span>
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
						<v-spacer />
						<v-btn
							:disabled="carregando"
							class="px-2 mb-1 ml-auto mt-2 mr-2"
							color="primary"
							elevation="0"
							small
							@click="abrirDialog(
                                dashboard.vendasecao, 
								{
									descrsecao: {nome: 'Seção', tipo: 'texto'},
									valvenda: {nome: 'Valor', tipo: 'dinheiro'},
									margem: {nome: 'Margem', tipo: 'porcentagem', class: 'text-truncate'},
									percentual: {nome: 'Proporcional', tipo: 'porcentagem', class: 'text-truncate'},
								},
                                'Venda realizada por categoria'
                            )"
						>
							<v-icon size="18">mdi-magnify-plus-outline</v-icon>Ver mais
						</v-btn>
					</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<GraficoBarDashboard
							v-if="dashboard.vendasecao"
							:dataSeries="[
                                {
                                    name: 'Categoria',
                                    data: dashboard.vendasecao.map((v) => {return v.percentual})
                                },
                            ]"
							:categories="dashboard.vendasecao.map((v) => {return v.descrsecao})"
							:horizontal="false"
							:money="false"
							:porcentagem="true"
							:composto="false"
							height="300"
							:distributed="true"
							:dataLabels="{ position : 'bottom', orientation: 'vertical', offsetY : 20 }"
							:color="corMod2(dashboard.vendasecao, 'percentual')"
						/>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" class="d-block d-md-none">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title>
						<span class="text-truncate">Venda por categoria (%)</span>
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
						<v-btn
							:disabled="carregando"
							class="px-2 mb-1 ml-auto mt-2 mr-2"
							color="primary"
							elevation="0"
							small
							@click="abrirDialog(
								dashboard.vendasecao, 
								{
									descrsecao: {nome: 'Seção', tipo: 'texto'},
									valvenda: {nome: 'Valor', tipo: 'dinheiro'},
									margem: {nome: 'Margem', tipo: 'porcentagem', class: 'text-truncate'},
									percentual: {nome: 'Proporcional', tipo: 'porcentagem', class: 'text-truncate'},
								},
								'Venda realizada por categoria'
							)"
						>
							<v-icon size="18">mdi-magnify-plus-outline</v-icon>
						</v-btn>
					</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<template v-if="dashboard.vendasecao">
							<v-simple-table dense>
								<tbody>
									<tr v-for="(u,i) in dashboard.vendasecao" :key="i">
										<td
											style="height: 22px"
											width="30%"
											class="text-truncate text-caption"
										>{{u.descrsecao | formataTextoCurto}}</td>
										<td style="height: 22px">
											<v-progress-linear
												rounded
												:value="u.percentual"
												:color="shadeColor('#002D56', 100 - u.percentual / 3)"
												class="white--text"
												height="17"
											>
												<template v-slot:default="{ value }">
													<span class="text-caption">{{ value.toFixed(2) }}%</span>
												</template>
											</v-progress-linear>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</template>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" md="6" v-if="usuario.idgrupo != 7" class="d-none d-md-block">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title class="mb-n1">
						Margem de lucro por categoria (%)
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
					</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<GraficoBarDashboard
							v-if="dashboard.vendasecao"
							:dataSeries="[
                                {
                                    name: 'Categoria',
                                    data: dashboard.vendasecao.map((v) => {return v.margem.toFixed(2)})
                                },                             
                            ]"
							:categories="dashboard.vendasecao.map((v) => {return v.descrsecao})"
							:horizontal="false"
							:money="false"
							:porcentagem="true"
							height="300"
							:distributed="true"
							:dataLabels="{ position : 'bottom', orientation: 'vertical', offsetY : 20 }"
							:color="corMod2(dashboard.vendasecao, 'margem')"
						/>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
			<v-col cols="12" class="d-block d-md-none" v-if="usuario.idgrupo != 7">
				<v-card>
					<v-progress-linear indeterminate absolute :active="carregando" />
					<v-card-title>
						<span class="text-truncate">Margem de lucro por categoria (%)</span>
						<v-spacer />
						<v-img class="mx-auto" max-width="220" src="../../assets/icons/logo_dark_min.png" />
					</v-card-title>
					<v-card-text v-if="carregando">
						<v-skeleton-loader class="mx-auto" width="100%" height="300" type="image, image"></v-skeleton-loader>
					</v-card-text>
					<v-card-text v-else>
						<template v-if="dashboard.vendasecao">
							<v-simple-table dense>
								<tbody>
									<tr v-for="(u,i) in dashboard.vendasecao" :key="i">
										<td
											style="height: 22px"
											width="30%"
											class="text-truncate text-caption"
										>{{u.descrsecao | formataTextoCurto}}</td>
										<td style="height: 22px">
											<v-progress-linear
												rounded
												:value="u.margem"
												:color="shadeColor('#002D56', 100 - u.margem / 3)"
												class="white--text"
												height="17"
											>
												<template v-slot:default="{ value }">
													<span class="text-caption">{{ value.toFixed(2) }}%</span>
												</template>
											</v-progress-linear>
										</td>
									</tr>
								</tbody>
							</v-simple-table>
						</template>
						<span v-else>Nenhum registro encontrado</span>
					</v-card-text>
				</v-card>
			</v-col>
		</template>
		<template v-else>
			<v-col>
				<v-card class="pa-5" color="primary" dark>
					<v-card-title class="mb-1">Bem-vindo</v-card-title>
					<v-card-text class="text-body-1">Para navegar pelo sistema selecione uma opção no menu acima</v-card-text>
				</v-card>
			</v-col>
		</template>
	</v-row>
</template>

<script>
import { mapState } from "vuex";
import axios from "axios";
import InputDatePicker from "../Widgets/InputDatePicker";
import CardDashboard from "../Widgets/CardDashboard";
import MenuInfo from "../Widgets/MenuInfo";
import GraficoBarDashboard from "../Widgets/GraficoBarDashboard";
import DialogDashboards from "../Widgets/DialogDashboards";
import GraficoLineColumnDashboard from "../Widgets/GraficoLineColumnDashboard";

export default {
	name: "DashboardVendas",
	components: {
		InputDatePicker,
		CardDashboard,
		MenuInfo,
		GraficoBarDashboard,
		DialogDashboards,
		GraficoLineColumnDashboard,
	},
	data: () => ({
		tempoRefresh: 60,
		autoRefresh: false,
		usuariosAutorizados: [1, 4, 5, 6, 8, 20],
		filtroCompleto: [1, 4, 5,  8, 20],
		filtroComFilial: [1, 4, 5, 8, 20],
		sheet: false,
		dialog: { status: false, dados: [], header: {} },
		carregando: false,
		filiais: [],
		regionais: [],
		dashboard: {},
		dia: `${new Date().toLocaleDateString("pt-BR")}`,
		busca: {
			dtinicio: `${new Date().getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-01`,
			dtfim: `${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getFullYear()}-${
				(new Date(
					new Date().getFullYear(),
					new Date().getMonth() + 1,
					0
				).getMonth() +
					1 <
				10
					? "0"
					: "") +
				(new Date().getMonth() + 1)
			}-${new Date(
				new Date().getFullYear(),
				new Date().getMonth() + 1,
				0
			).getDate()}`,
			idfiliais: [],
		},
	}),
	computed: {
		...mapState(["backendUrl", "pgLimit", "usuario"]),
		permissaoDashboard() {
			if (this.usuariosAutorizados.indexOf(this.usuario.idgrupo) != -1) {
				return true;
			} else {
				return false;
			}
		},
	},
	methods: {
		listarEmpresas() {
			this.busca.idfiliais = [];
			return axios
				.post(`${this.backendUrl}filial/listar`, {
					limit: 9999,
					offset: 0,
					idregional: this.busca.idregional || null,
					busca: null,
				})
				.then((res) => {
					this.filiais = res.data;
					this.filiais.lista = this.filiais.lista.filter((v) => {
						return (
							v.idfilial !== 6 &&
							v.idfilial !== 12 &&
							v.idfilial !== 22 &&
							v.idfilial !== 25 &&
							v.idfilial !== 29 &&
							v.idfilial !== 32 &&
							v.idfilial !== 33 &&
							v.idfilial !== 34 &&
							v.idfilial !== 37
						);
					});
				});
		},
		listarRegionais() {
			return axios
				.post(`${this.backendUrl}filial/regional/listar`)
				.then((res) => {
					this.regionais = res.data;
				});
		},
		get() {
			let idfiliais = null;
			if (this.busca.idfiliais.length > 0) {
				idfiliais = this.busca.idfiliais.join(",");
			}
			if (this.usuario.idgrupo == 6) {
				idfiliais = `${this.usuario.idfilial}`;
			}
			this.dashboard = {};
			this.carregando = true;
			return axios
				.post(`${this.backendUrl}dashboard/principal/antiga`, {
					dtini: this.busca.dtinicio,
					dtfim: this.busca.dtfim,
					idregional: this.busca.idregional || null,
					idfilial: idfiliais || null,
				})
				.then((res) => {
					this.dashboard = res.data;
					this.dashboard.vendametavendedor.forEach((v) => {
						if (v.meta != 0) {
							v.atingido = (v.venda / v.meta) * 100;
						} else {
							v.atingido = 0;
						}
					});
					this.dashboard.vendafilial =
						this.dashboard.vendafilial.sort(
							(a, b) => b.peratingimento - a.peratingimento
						);
					this.dashboard.vendasecao = this.dashboard.vendasecao.map(
						(v) => {
							return {
								...v,
								percentual:
									(v.valvenda /
										this.dashboard.vendasecao
											.map((ve) => {
												return ve.valvenda;
											})
											.reduce((a, va) => {
												return a + va;
											})) *
									100,
							};
						}
					);
					if (this.usuario.idgrupo == 7) {
						this.dashboard.headervendedor = {
							filial: { nome: "Filial", tipo: "texto" },
							idvendedor: { nome: "ID", tipo: "texto" },
							vendedor: { nome: "Vendedor", tipo: "texto" },
							meta: { nome: "Meta", tipo: "dinheiro" },
							venda: { nome: "Realizado", tipo: "dinheiro" },
							atingido: { nome: "Atingido", tipo: "porcentagem" },
						};
					} else {
						this.dashboard.headervendedor = {
							filial: { nome: "Filial", tipo: "texto" },
							idvendedor: { nome: "ID", tipo: "texto" },
							vendedor: { nome: "Vendedor", tipo: "texto" },
							meta: { nome: "Meta", tipo: "dinheiro" },
							venda: { nome: "Realizado", tipo: "dinheiro" },
							atingido: { nome: "Atingido", tipo: "porcentagem" },
							margem: { nome: "Atingido", tipo: "porcentagem" },
						};
					}
					this.carregando = false;
					if (this.autoRefresh) {
						this.tempoRefresh = 60;
						setTimeout(() => {
							this.get();
						}, 60000);
					}
				})
				.catch(() => {
					this.carregando = false;
				});
		},
		timerRefresh() {
			if (this.tempoRefresh < 0) {
				this.tempoRefresh = 60;
			}
			setTimeout(() => {
				this.tempoRefresh--;
				this.timerRefresh();
			}, 1000);
		},
		abrirDialog(dados, header, title) {
			this.dialog.status = true;
			this.dialog.dados = dados;
			this.dialog.header = header;
			this.dialog.title = title;
		},
		maiorValor() {
			let array = this.dashboard.vendametavendedor
				.filter((v) => {
					return v.vendedor != "VENDEDOR";
				})
				.filter((v) => {
					return v.vendedor != "Caixa Rapido";
				})
				.filter((v) => {
					return v.venda > 0;
				})
				.slice(0, 10)
				.map((v) => {
					return v.meta.toFixed(2);
				});

			this.dashboard.vendametavendedor
				.filter((v) => {
					return v.vendedor != "VENDEDOR";
				})
				.filter((v) => {
					return v.vendedor != "Caixa Rapido";
				})
				.filter((v) => {
					return v.venda > 0;
				})
				.slice(0, 10)
				.map((v) => {
					array.push(v.venda.toFixed(2));
				});

			return (parseInt(Math.max.apply(null, array) / 100) + 1) * 100;
		},
		corMod1(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor;
				cor = this.corSemafaro(v[campo]);
				return this.shadeColor(cor, 100 - v[campo] / 3);
			});
			return cores;
		},
		corMod2(array, campo) {
			let cores = [];
			cores = array.map((v) => {
				let cor = "#002D56";
				return this.shadeColor(cor, 100 - v[campo] / 3);
			});
			return cores;
		},
		corSemafaro(valor) {
			let cor;
			if (valor >= 90) cor = "#002D56";
			if (valor < 90) cor = "#F2B90F";
			if (valor < 70) cor = "#951015";
			return cor;
		},
		shadeColor(color, percent) {
			var R = parseInt(color.substring(1, 3), 16);
			var G = parseInt(color.substring(3, 5), 16);
			var B = parseInt(color.substring(5, 7), 16);
			R = parseInt((R * (100 + percent)) / 100);
			G = parseInt((G * (100 + percent)) / 100);
			B = parseInt((B * (100 + percent)) / 100);
			R = R < 255 ? R : 255;
			G = G < 255 ? G : 255;
			B = B < 255 ? B : 255;
			var RR =
				R.toString(16).length == 1
					? "0" + R.toString(16)
					: R.toString(16);
			var GG =
				G.toString(16).length == 1
					? "0" + G.toString(16)
					: G.toString(16);
			var BB =
				B.toString(16).length == 1
					? "0" + B.toString(16)
					: B.toString(16);
			return "#" + RR + GG + BB;
		},
		async init() {
			await this.listarEmpresas();
			await this.listarRegionais();
			await this.get();
			this.timerRefresh();
		},
	},
	watch: {
		"busca.dtfim": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtinicio = this.busca.dtfim;
			}
		},
		"busca.dtinicio": function () {
			if (this.busca.dtfim < this.busca.dtinicio) {
				this.busca.dtfim = this.busca.dtinicio;
			}
		},
		autoRefresh: function () {
			if (this.autoRefresh) {
				this.tempoRefresh = 60;
				this.get();
			}
		},
	},
	created() {
		this.init();
	},
};
</script>

<style scoped>
</style>